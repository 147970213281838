import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL + "setting";

// api with base pathname /settings will be called from here

export const AddSetting = (reqData: any) => {
  return axios.post(`${API_URL}/add`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const AddSubscriptionDetails = (reqData: any) => {
  return axios.post(`${API_URL}/subscriptionDetails`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const UpdateSubscriptionDetails = (reqData: any) => {
  return axios.put(`${API_URL}/subscriptionDetails`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const editSettings = (reqData: any) => {
  return axios.put(`${API_URL}`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getHomePageSettings = () => {
  return axios.get(`${API_URL}/get`);
};

export const addAboutus = (reqData: any) => {
  return axios.post(`${API_URL}/aboutus`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const editAboutus = (reqData: any) => {
  return axios.put(`${API_URL}/aboutus`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const addBenefit = (reqData: any) => {
  return axios.post(`${API_URL}/addBenefit`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const editBenefit = (reqData: any) => {
  return axios.put(`${API_URL}/benefit`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const deleteBenefit = (reqData: any) => {
  return axios.delete(`${API_URL}/delete`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const deleteAboutus = (reqData: any) => {
  return axios.delete(`${API_URL}/delete_aboutus`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const updateFAQ = (reqData: Object) => {
  return axios.put(`${API_URL}/update_faq`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const deleteFAQ = (reqData: any) => {
  return axios.delete(`${API_URL}/delete_faq`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const addBanner = (reqData: any) => {
  return axios.post(`${API_URL}/banners`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const editBanner = (reqData: any) => {
  return axios.put(`${API_URL}/banners`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const deleteBanner = (reqData: any) => {
  return axios.delete(`${API_URL}/banners`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const addCMS = (reqData: any) => {
  return axios.post(`${API_URL}/cms`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const editCMS = (reqData: any) => {
  return axios.put(`${API_URL}/cms`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const GetSettings = (reqData: any) => {
  return axios.get(`${API_URL}`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const addFaq = (reqData: any) => {
  return axios.post(`${API_URL}/faq`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const DeleteUser = (reqData: any) => {
  return axios.delete(API_URL, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const statusChange = (reqData: any) => {
  return axios.put(`${API_URL}/activeInactive`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const aboutUsStatus = (reqData: Object) => {
  return axios.put(`${API_URL}/changeStatus`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const saveOtherAppSettings = (reqData: Object) => {
  return axios.put(`${API_URL}/updateOtherSetting`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
