import { createSlice } from "@reduxjs/toolkit";

const initialState: { filter: any } = {
  filter: {},
};

const intrestedBuyers = createSlice({
  name: "intrestedBuyer",
  initialState,
  reducers: {
    setFilter(state, action) {
      const { key, value } = action.payload;
      state.filter = {
        ...state.filter,
        [key]: [...(state.filter[key] || []), value],
      };
    },
    EmptyFilter(state, action) {
      state.filter = action.payload;
    },
    setFilterRadio(state, action) {
      const { key, value } = action.payload;
      if (value) {
        state.filter = {
          ...state.filter,
          [key]: value,
        };
      } else {
        const { [key]: removedValue, ...newFilter } = state.filter;
        state.filter = newFilter;
      }
    },
    popFilterValue(state, action) {
      const { key, value } = action.payload;

      if (state.filter[key]) {
        // If key is a string and it exists in state, remove the value from the array
        if (Array.isArray(state.filter[key])) {
          const updatedArray = state.filter[key].filter(
            (item: any) => item !== value
          );
          const updatedFilter = { ...state.filter, [key]: updatedArray };
          return { ...state, filter: updatedFilter };
        } else {
          const updatedFilter = { ...state.filter, [key]: "" };
          return { ...state, filter: updatedFilter };
        }
      }

      return state; // Return the original state if the key doesn't exist
    },
  },
});

export const { setFilter, EmptyFilter, popFilterValue, setFilterRadio } =
  intrestedBuyers.actions;
export default intrestedBuyers.reducer;
