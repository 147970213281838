// ==============================|| OVERRIDES - FORM HELPER TEXT ||============================== //

export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 8
        }
      }
    }
  };
}
