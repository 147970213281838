import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL + "user";

// api's with base url as /user will be called from here

export const getUser = (reqData: Object) => {
  return axios.get(`${API_URL}/single`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getUserById = (reqData: Object) => {
  return axios.get(`${API_URL}`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getAdminUserDetails = (reqData: Object) => {
  return axios.get(`${API_URL}/user-detail`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const IntrestedBuyersFilter = (reqData: Object, { signal }: any) => {
  return axios.get(`${API_URL}/interestedBuyer`, {
    params: reqData,
    signal: signal,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const updateUser = (reqData: Object) => {
  return axios.put(API_URL, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const requestOtp = (reqData: Object) => {
  return axios.put(`${API_URL}/request-otp`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const requestEmailOtp = (reqData: Object) => {
  return axios.put(`${API_URL}/request-emailOtp`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const verifyOtp = (reqData: Object) => {
  return axios.put(`${API_URL}/verify-otp`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const verifyEmailOtp = (reqData: Object) => {
  return axios.put(`${API_URL}/verify-emailOtp`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const getUserByToken = () => {
  return axios.get(API_URL + "/token", {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const GetAllUsers = (reqData: any) => {
  return axios.get(`${API_URL}/all`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const DeleteUser = (reqData: any) => {
  return axios.delete(API_URL, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const statusChange = (reqData: any) => {
  return axios.put(`${API_URL}/changeStatus`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const adminUpdate = (reqData: Object) => {
  return axios.put(API_URL + "/admin/update", reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const shortlistedCar = (reqData: Object, { signal }: any) => {
  return axios.get(`${API_URL}/shortlistedCar`, {
    params: reqData,
    signal: signal,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getConsultantList = (reqData: Object, { signal }: any) => {
  return axios.get(`${API_URL}/consultantList`, {
    params: reqData,
    signal: signal,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const sendFeedback = (reqData: Object) => {
  return axios.put(`${API_URL}/feedback-request`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const userWithCar = (reqData: Object) => {
  return axios.get(`${API_URL}/userWithCar`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getSellerInfo = (reqData: Object) => {
  return axios.get(`${API_URL}/sellerInfo`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const verifyDocument = (reqData: Object) => {
  return axios.post(`${API_URL}/verify-document`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
