import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import events from "../utils/events";
import {
  resetActiveChannelMessages,
  setActiveChannelRoomId,
  setChatIdeal,
  setChatSkip,
  setDrawerSkip,
  setStopDrawerLoad,
  setTotalChatCount,
  setUserSwitching,
} from "store/reducers/chat";
import { dispatch, useSelector } from "store";
import { setChatsInit, setUserAndEmit } from "utils/helper";

const WindowFocusHandler = () => {
  const chatIdeal = useSelector((state) => state?.chat?.chatIdeal);
  // const [show, setShow] = useState(false);
  let timeout: any;
  // const navigate = useNavigate();
  const idleTimeThreshold = 900000;
  const { user, socket }: any = useAuth();
  const idel = useSelector((state) => state?.chat?.chatIdeal);
  const activeChanel: any = useSelector((state) => state?.chat?.activeChanel);
  useEffect(() => {
    let timeoutId: any;

    const handleIdle = () => {
      // setIdle(true);
      dispatch(resetActiveChannelMessages([]));
      socket.emit(events.USER_IDEAL, { userId: user?._id });
      dispatch(setChatIdeal(true));
    };

    const handleActive = () => {
      // setIdle(false);
      if (idel) {
        if (activeChanel?.roomId) {
          dispatch(setUserSwitching(true));
          dispatch(resetActiveChannelMessages([]));
          dispatch(setChatSkip(1));
          dispatch(setTotalChatCount(0));
          setChatsInit(activeChanel?.roomId, 1, socket, user);
          dispatch(setStopDrawerLoad(false));
        }
        dispatch(setChatIdeal(false));
        setUserAndEmit(user, socket);
      }

      dispatch(setDrawerSkip(1));

      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleIdle, idleTimeThreshold);
    };

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleIdle, idleTimeThreshold);
    };

    const handleInteraction = () => {
      if (chatIdeal) {
        handleActive();
      } else {
        resetTimeout();
      }
    };

    window.addEventListener("mousemove", handleInteraction);
    window.addEventListener("keypress", handleInteraction);
    window.addEventListener("scroll", handleInteraction);

    timeoutId = setTimeout(handleIdle, idleTimeThreshold);

    return () => {
      window.removeEventListener("mousemove", handleInteraction);
      window.removeEventListener("keypress", handleInteraction);
      window.removeEventListener("scroll", handleInteraction);
      clearTimeout(timeoutId);
    };
  }, [idleTimeThreshold, chatIdeal, socket]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!chatIdeal) {
        socket.emit(events.USER_IDEAL, { userId: user?._id });
        dispatch(setChatIdeal(true));
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [chatIdeal, dispatch, socket, user]);
  return null;
};

export default WindowFocusHandler;
