import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL + "filterCar";
const API_URL_SEARCH = process.env.REACT_APP_BASEURL + "searchLog";

// api's with base pathname /filterCar and /searchLog are used here

export const getAllCarsData = () => {
  return axios.get(`${API_URL}/all`, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getCityWithZipcode = (reqData: any) => {
  return axios.get(`${API_URL}/cityWithZipCode`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getCityWithName = (reqData: any) => {
  return axios.get(`${API_URL}/city`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getAllSearchData = (reqData: any) => {
  const apiUrl = `${API_URL_SEARCH}/all`;
  return axios.get(apiUrl, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const AddSearchedData = (reqData: Object) => {
  return axios.post(`${API_URL_SEARCH}/`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
