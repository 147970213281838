import { useEffect, useState } from "react";

// project-imports
import Routes from "routes";
import ThemeCustomization from "themes";

import Loader from "components/Loader";
import Locales from "components/Locales";
import RTLLayout from "components/RTLLayout";
import ScrollTop from "components/ScrollTop";
// import Customization from 'components/Customization';
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import favicon from "images/favicon.png";

import { dispatch } from "store";
import { fetchDashboard } from "store/reducers/menu";

import ReactGA from "react-ga";
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID, { debug: true });
}

// auth-provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import { getHomePageSettings } from "services/setting.services";
import { useSelector } from "react-redux";
import { setHomepage, setLoader } from "store/reducers/homepage";
import GreyLoader from "components/@extended/GreyLoader";
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const data = useSelector((state: any) => state?.homepage?.data);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchDashboard()).then(() => {
      setLoading(true);
    });
  }, []);

  const faviconImg = data?.icon?.file || favicon;
  useEffect(() => {
    dispatch(setLoader(true));
    getHomePageSettings()
      .then((data) => {
        dispatch(setHomepage(data?.data?.data));
        // setData(data?.data?.data);
        dispatch(setLoader(false));
      })
      .catch((err: any) => {
        console.error(err);
        dispatch(setLoader(false));
      });
  }, []);
  useEffect(() => {
    // Dynamically set the favicon icon
    if (faviconImg) {
      updateIcons(data?.icon?.file || faviconImg);
    }
    document.title =
      "AUTOMATCHPROS: Your Trusted Online Platform for Personalized Car Buying and Selling";
  }, [faviconImg, data]);

  const updateIcons = (newFavicon: string) => {
    const updateIcon = (rel: string, newIcon: string) => {
      let link: any =
        document.querySelector(`link[rel~='${rel}']`) ||
        document.createElement("link");

      link.rel = rel;
      link.href = newIcon;

      // Remove existing icon and append the updated one
      const head = document.getElementsByTagName("head")[0];
      const existingLink = head.querySelector(`link[rel~='${rel}']`);
      if (existingLink) {
        head.removeChild(existingLink);
      }

      head.appendChild(link);
    };

    // Update favicon
    updateIcon("icon", newFavicon);

    // Update apple-touch-icon
    updateIcon("apple-touch-icon", newFavicon);
  };

  window.addEventListener("resize", () => {
    const screenWidth = window.innerWidth;
    const smallScreenThreshold = 600; // Define your small screen threshold here

    // If the screen width is smaller than the threshold, call updateIcons
    if (screenWidth < smallScreenThreshold) {
      if (faviconImg) {
        updateIcons(data?.icon?.file || faviconImg);
      }
    }
  });

  if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <GreyLoader />

          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  {/* <Customization /> */}
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
