import { ReactNode, useEffect, useRef } from "react";

// material-ui
import { styled } from "@mui/material/styles";

// third-party
import { SnackbarProvider } from "notistack";
import NotificationSound from "../../assets/notification-sound.mp3";
// project-imports
import { useSelector } from "store";
import { SnackbarUtilsConfigurator } from "utils/ToastNotistack";

// assets
import { CloseCircle, InfoCircle, TickCircle, Warning2 } from "iconsax-react";
// import useAuth from "hooks/useAuth";
// import events from "../../utils/events";
// import { setActiveChannelMessages } from "store/reducers/chat";
// import Snackbar from "utils/Snackbar";

// custom styles
const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-variantError": {
    backgroundColor: theme.palette.error.main,
  },
  "&.SnackbarItem-variantSuccess": {
    backgroundColor: theme.palette.success.main,
  },
  "&.SnackbarItem-variant": {
    backgroundColor: theme.palette.success.main,
  },
  "&.SnackbarItem-variantInfo": {
    backgroundColor: theme.palette.info.main,
  },
  "&.SnackbarItem-variantWarning": {
    backgroundColor: theme.palette.warning.light,
  },
}));

// ===========================|| SNACKBAR - NOTISTACK ||=========================== //

const Notistack = ({ children }: { children: ReactNode }) => {
  const snackbar = useSelector((state) => state.snackbar);
  const iconSX = { marginRight: 8, fontSize: "1.15rem" };
  const audioPlayer = useRef(null);
 
  return (
    <>
      <audio ref={audioPlayer} src={NotificationSound} />
      <StyledSnackbarProvider
        maxSnack={snackbar.maxStack}
        dense={snackbar.dense}
        iconVariant={
          snackbar.iconVariant === "useemojis"
            ? {
                success: <TickCircle style={iconSX} />,
                error: <CloseCircle style={iconSX} />,
                warning: <Warning2 style={iconSX} />,
                info: <InfoCircle style={iconSX} />,
              }
            : undefined
        }
        hideIconVariant={snackbar.iconVariant === "hide" ? true : false}
      >
        <SnackbarUtilsConfigurator />
        {children}
      </StyledSnackbarProvider>
    </>
  );
};

export default Notistack;
