// import Footer from "pages/Footer2";
import useAuth from "hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import NotificationSound from "../../assets/notification-sound.mp3";
import events from "../events";
import Snackbar from "utils/Snackbar";
import { dispatch, useSelector } from "store";
import { resetActiveChannel, setChatDetails } from "store/reducers/chat";
import WindowFocusHandler from "components/AutoLogout";
import { Box, Typography } from "@mui/material";
import NoSubscription from "assets/images/img_subscription.png";

const PrivateRoute = () => {
  const { socket, user } = useAuth();
  const [isTokenValid, setIsTokenValid] = useState(
    localStorage.getItem("serviceToken") ? true : false
  );
  const audioPlayer = useRef(null);
  const location = useLocation();
  console.log(user?.type);
  const consent =
    user?.type !== "shopper" && location.pathname.startsWith("/myaccount")
      ? true
      : user?.type !== "shopper"
      ? user?.planHistoryId?.active
      : true;
  useEffect(() => {
    const checkTokenValidity = async () => {
      if (location.pathname !== "/myaccount") {
        localStorage.setItem("recieverId", "");
        dispatch(resetActiveChannel());
      }
      const token = localStorage.getItem("serviceToken");
      if (token) {
        // You may want to add more complex logic here to check if the token is valid
        if (isTokenValid === false) setIsTokenValid(true);
      } else {
        if (isTokenValid === true) setIsTokenValid(false);
      }
    };

    checkTokenValidity();
  }, [location.pathname]);

  useEffect(() => {
    // setChannel(user?.email);

    socket?.on(events.P_MESSAGE_SEND, addPMessage);
  }, [socket]);
  const drawerChats = useSelector((chats) => chats?.chat?.chats);
  const addPMessage = (data: {
    channel: string;
    message: any;
    roomId: string;
    senderDetail: any;
  }) => {
    const message = data?.message;
    const senderDetail = data?.senderDetail;

    const recId = localStorage.getItem("recieverId");

    if (message?.sender === user?._id || message?.sender === recId) {
      // dispatch(setActiveChannelMessages(message));
    } else {
      dispatch(
        setChatDetails({
          senderId: message?.sender,
          messageId: message?.id,
          message: message?.message,
          roomId: data?.roomId || senderDetail?.chatRoomId,
        })
      );
      audioPlayer.current.play();
      Snackbar(
        `You recieved a message from ${senderDetail?.firstName}`,
        "success"
      );
    }
    // console.log(drawerChats,'this is drawer chats');
    // if (drawerChats.length === 0) {
    //   setUserAndEmit(user, socket, "");
    // }
  };

  return isTokenValid ? (
    <>
      <WindowFocusHandler />
      <audio ref={audioPlayer} src={NotificationSound} />
      {consent ? (
        <Outlet />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            textAlign: "center",
            backgroundColor: "#D1E8FF",
            alignItems: "center",
          }}
        >
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <img
                src={NoSubscription}
                style={{
                  width: "100%",
                  height: "auto",
                  userSelect: "none",
                  pointerEvents: "none",
                }}
                onContextMenu={(e) => e.preventDefault()}
              />
            </Box>
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: { xs: "28px", sm: "30px", md: "34px", lg: "34px" },
                lineHeight: "40px",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              Your subscription has been expired
            </Typography>
            <Typography
              color="inherit"
              variant="h6"
              sx={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "23px",
                textAlign: "center",
                px: { lg: "15%", md: "15%", sm: "15%", xs: "0px" },
              }}
            >
              Please Purchase the plan from myaccount or main page
            </Typography>
          </Box>
        </Box>
      )}
      {/* <Footer /> */}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
