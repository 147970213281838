import ContentLoader from "react-content-loader";

const GeneralSkeleton = (props: any) => {
  return (
    <ContentLoader
      height={props.height}
      width={props.width}
      speed={1}
      backgroundColor="#B3D7FF" // Slightly darker shade of #CCE6FF
      foregroundColor="#FFEBB2" // Change this to the desired foreground color
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
    </ContentLoader>
  );
};

export default GeneralSkeleton;
