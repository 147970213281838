// project-imports

// types
import { NavItemType } from "types/menu";
import users from "./users";
// import plans from "./plans";
import blogs from "./blogs";
// import feedback from "./feedback";
import settings from "./settings";
import subscriptions from "./subscriptions";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [users, blogs, subscriptions, settings],
};

export default menuItems;
