// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project-imports
import chat from "./chat";
import calendar from "./calendar";
import menu from "./menu";
import snackbar from "./snackbar";
import productReducer from "./product";
import cartReducer from "./cart";
import kanban from "./kanban";
import invoice from "./invoice";
import blogSlice from "./blog";
import user from "./user";
import landing from "./landing";
import homepage from "./homepage";
import myAccount from "./myAccount";
import filters from "./filters";
import intrestedBuyers from "./intrestedBuyers";
import notification from "./notification";
import subscriptionSlice from "./subscription";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  calendar,
  notification,
  menu,
  snackbar,
  homepage: homepage,
  intrestedBuyers: intrestedBuyers,
  myAccount: myAccount,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "able-pro-material-ts-",
    },
    cartReducer
  ),
  product: productReducer,
  filters: filters,
  kanban,
  subscription: subscriptionSlice,
  landing: landing,
  blogs: blogSlice,
  invoice,
  user,
});

export default reducers;
