import moment from "moment-timezone";
import events from "./events";
import useAuth from "hooks/useAuth";
import { useState } from "react";

import Resizer from "react-image-file-resizer";
import axios from "axios";
import { CookieStorage } from "amazon-cognito-identity-js";
import { getAllNotification } from "services/notification.service";
import Snackbar from "./Snackbar";

// import Compressor from "browser-image-compression";

export const getDateTimeAsTimeZone = (
  dateTime: string,
  orgTimeZone?: string,
  dateformat?: string,
  timeFormat?: string
) => {
  const timezone = orgTimeZone ? orgTimeZone : "America/Chicago";
  const date = dateformat ? dateformat : "MM/DD/YYYY";
  const time = timeFormat ? timeFormat : "12-hour";

  return dateTime
    ? moment(dateTime).tz(timezone).format(date)
    : "";
  // return moment(dateTime).tz(timezone).format(`${date} ${time === "12-hour" ? "hh:mm A" : "HH:mm"}`);
};

export const formatDate = (
  dateTime: string,
  format: string,
  timezone: string
) => {
  if (dateTime && format && timezone)
    return moment(dateTime).tz(timezone).format(`${format} h:mm A `);
};

export function maskEmail(email: string | null) {
  if (email) {
    return email.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, "*") + c
    );
  }
}
export function addTimeToPasscode() {
  const currentTime = new Date();
  currentTime.setMinutes(currentTime.getMinutes() + 2);
  return currentTime;
}
export function TimeDifference(date: any) {
  const moment = require("moment");
  const date1 = moment(new Date());
  const date2 = moment(date);
  const timeDifferenceMilliseconds = date2.diff(date1);
  const duration = moment.duration(timeDifferenceMilliseconds);
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return { hours, minutes, seconds };
}

export function currenyFormat(num: number | string) {
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;
  if (isNaN(parsedNum)) {
    // console.error("Invalid input for currencyFormat:", num);
    return "";
  }
  const formattedNumber = parsedNum
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return "$" + formattedNumber.replace(/\.00$/, "");
}
export function commaSeparatedFormat(number: number | string) {
  const stringValue =
    typeof number === "number" ? number.toFixed(2) : number.toString();

  const [integerPart, decimalPart] = number.toString().split(".");

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Check if decimalPart exists and if it's not empty
  const formattedDecimal =
    `${number}`.charAt(`${number}`.length - 1) === "." || decimalPart
      ? `.${decimalPart}`
      : "";

  // Only add the decimal part if it's not .00
  const finalDecimal = formattedDecimal === ".00" ? "" : formattedDecimal;

  return formattedInteger + finalDecimal;
}

export function getRoomId(userAuth: any, user: { _id: string }) {
  userAuth.socket.emit(events.ROOM_ID, {
    senderId: userAuth?.user?._id,
    receiverId: user?._id,
  });
}

export async function getIpAddress() {
  let ip: any = "";
  let timeZone: any = "";
  const TIMEOUT_DURATION = 10000;
  await axios
    .get("https://ipinfo.io")
    .then((response) => {
      console.log(response?.data,'ip details');
      ip = response?.data?.ip;
      timeZone = response?.data?.timezone;
    })
    .catch(async (error) => {
      await axios
        .get("https://www.cloudflare.com/cdn-cgi/trace", {
          timeout: TIMEOUT_DURATION,
        })
        .then((data) => {
          console.log(data, "this is data");
          const ipRegex = /ip=([\d.]+)/;
          const ipAddressMatch = data?.data.match(ipRegex);
          console.log("this is data", ipAddressMatch);
          ip = ipAddressMatch?.[1];
          // return ipAddressMatch?.[1];
        })
        .catch(async (error) => {
          await axios
            .get("https://checkip.amazonaws.com", {
              timeout: TIMEOUT_DURATION,
            })
            .then((data) => {
              ip = data;
              // return data;
            })
            .catch((error) => {
              return null;
            });
        });
    });
  localStorage.setItem("systemIp", ip.toString());
  localStorage.setItem("timeZone", timeZone);
  return { ip: ip, timeZone: timeZone };
}

export function setChatsInit(
  chatRoomId: string,
  chatSkip: number,
  socket: any,
  myUser: any
) {
  // console.log(
  //   chatRoomId,
  //   chatSkip,
  //   myUser,
  //   socket,
  //   myUser,
  //   "this is route file"
  // );
  if (myUser?._id && chatRoomId && localStorage.getItem("recieverId")) {
    socket.emit(events.INIT_CHATS, {
      senderId: myUser?._id,
      receiverId: localStorage.getItem("recieverId"),
      roomId: chatRoomId,
      skip: chatSkip,
      limit: 20,
    });
  }
}

export function setUserAndEmit(
  newUser: any,
  socket: any,
  search?: string,
  skip?: number
) {
  const userData: any = {
    name: newUser?.firstName,
    nickName: newUser?._id,
    loginUserId: newUser?._id,
    socketId: socket && socket?.id,
    skip: skip ? skip : 1,
    limit: 10,
  };
  userData.search = search;

  socket && socket.emit(events.NEW_USER, userData);
}

export async function getNotification() {
  let response = null;
  await getAllNotification()
    .then((data) => {
      response = data;
    })
    .catch((err) => {
      Snackbar(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Error, while loading data",
        "error"
      );
    });

  return response;
}

export function hasPublicDomain(email: string) {
  const domainName = email.split("@")[1];

  const publicDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "yopmail.com",
    "aol.com",
    "yahoo.com",
    "outlook.com",
    "icloud.com",
    "protonmail.com",
    "mail.com",
    "temp-mail.org",
    "10minutemail.com",
    "guerrillamail.com",
    "mailinator.com",
    "dispostable.com",
    "yandex.com",
    "zoho.com",
    "gmx.com",
  ];

  return publicDomains.includes(domainName);
}
// export  function initSocket() {
//   socket.on(events.INIT_CHATS, initChats);
//   socket.on(events.ROOM_ID, setRoomId);
//   socket.on(events.TYPING, addTyping);
//   socket.on(events.P_MESSAGE_SEND, addPMessage);
//   socket.on(events.P_TYPING, addPTyping);
//   socket.on(events.CREATE_CHANNEL, updateChats);
//   socket.on(events.ROOM_ID, updateChats);
// }

export function currenyFormatWithoutDollar(num: number | string) {
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;
  if (isNaN(parsedNum)) {
    // console.error("Invalid input for currencyFormat:", num);
    return "";
  }
  const formattedNumber = parsedNum
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return formattedNumber.replace(/\.00$/, "");
}

export const compressorDocument = (file: any, dimensions: any) => {
  function compressorFiler(file: any) {
    // var compressedFile = file;
    var newHeight = dimensions.height;
    var newWidth = dimensions.width;
    const fileType = file?.type;
    const fileSize = file?.size;
    let compressFormat: string;

    if (fileSize <= 2000000) {
      return file;
    }

    if (fileType === "image/jpeg") {
      compressFormat = "JPEG";
      newHeight = dimensions.height * 0.8;
      newWidth = dimensions.width * 0.8;
    } else if (fileType === "image/png") {
      compressFormat = "PNG";
      newHeight = dimensions.height * 0.35;
      newWidth = dimensions.width * 0.35;
    } else if (fileType === "image/webp") {
      newHeight = dimensions.height * 0.6;
      newWidth = dimensions.width * 0.6;
      compressFormat = "WEBP";
    } else if (fileType === "image/jpg") {
      newHeight = dimensions.height * 0.6;
      newWidth = dimensions.width * 0.6;
      compressFormat = "JPG";
    } else if (fileType === "image/svg+xml") {
      newHeight = dimensions.height * 0.6;
      newWidth = dimensions.width * 0.6;
      compressFormat = "SVG+XML";
    } else if (fileType === "image/jfif") {
      // newHeight = dimensions.height*0.60;
      // newWidth = dimensions.width*0.60;
      compressFormat = "JFIF";
    } else if (fileType === "image/gif") {
      return file;
    } else {
      compressFormat = "JPEG";
    }

    // console.log("Original file:", file)

    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        newWidth, // maxWidth
        newHeight, // maxHeight
        compressFormat, // compressFormat
        70, // quality
        0, // rotation
        (compressedFile: any) => {
          resolve(compressedFile);
        },
        "file" // outputType
      );
    });
  }
  return compressorFiler(file);
};
