import { ElementType, Suspense } from "react";

// project-imports
import Loader from "./Loader";
import Footer2 from "pages/Footer2";
import useAuth from "hooks/useAuth";
import Footer from "pages/Footer";
import { useLocation } from "react-router";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component: ElementType) => (props: any) => {
  const data = useAuth();
  const user = data?.user;
  const url = window.location.href;
  // console.log("url", url.split(".")[0].split("//")[1]);
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
      {url.split(".")[0].split("//")[1]!=="admin" ? (
        user && user?.type !== "admin" ? (
          <Footer2 />
        ) : (
          <Footer />
        )
      ) : (
        <></>
      )}
    </Suspense>
  );
};

export default Loadable;
