import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  filter: any;
  minmax: any;
  carData: Array<any>;
  searchedData: Array<any>;
} = {
  filter: {},
  minmax:["",""],
  carData: [],
  searchedData: [],
};

const landing = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setFilter(state, action) {
      const { key, value } = action.payload;
      state.filter = {
        ...state.filter,
        [key]: [...(state.filter[key] || []), value],
      };
    },
    setCarData(state, action) {
      state.carData = action.payload;
    },
    setMinMax(state, action) {
      state.minmax = action.payload;
    },
    setMinPriceRange(state, action) {
      state.filter.minPrice = action.payload;
    },
    setMaxPriceRange(state, action) {
      state.filter.maxPrice = action.payload;
    },
    setPostalCode(state, action) {
      state.filter.postalCode = action.payload;
    },
    EmptyFilter(state, action) {
      state.filter = action.payload;
      state.minmax = [];
    },
    setFilterRadio(state, action) {
      const { key, value } = action.payload;
      if (value) {
        state.filter = {
          ...state.filter,
          [key]: /\s+(?=[a-zA-Z0-9])/.test(value)
            ? value.replace(/\s+(?=[a-zA-Z0-9])/, " ").trim()
            : value[0] === " "
            ? value.slice(1).trim()
            : value,
        };
      } else {
        const { [key]: removedValue, ...newFilter } = state.filter;
        state.filter = newFilter;
      }
    },
    setFilterSearch(state, action) {
      const { key, value } = action.payload;
      if (value) {
        const formattedValue = value
          .split(/[\s,\.]+/)
          .map((item: any) => item.trim())
          .filter((item: any) => item !== "");
        state.filter = {
          ...state.filter,
          [key]: formattedValue,
        };
      } else {
        const { [key]: removedValue, ...newFilter } = state.filter;
        state.filter = newFilter;
      }
    },
    popFilterValue(state, action) {
      const { key, value } = action.payload;
      if (state.filter[key]) {
        if (Array.isArray(state.filter[key])) {
          const updatedArray = state.filter[key].filter(
            (item: any) => item !== value
          );
          const updatedFilter = { ...state.filter, [key]: updatedArray };
          return { ...state, filter: updatedFilter };
        } else {
          const updatedFilter = { ...state.filter, [key]: "" };
          return { ...state, filter: updatedFilter };
        }
      }
      return state;
    },
    setSearchedData(state, action) {
      state.searchedData = action.payload;
    },
    setSearches(state, action) {
      state.searchedData = [action.payload, ...state.searchedData];
    },
    setLogoutClear(state) {
      state.carData = [];
      state.searchedData = [];
      state.filter = {};
    },
  },
});

export const {
  setFilter,
  EmptyFilter,
  setMinMax,
  setMinPriceRange,
  setMaxPriceRange,
  setPostalCode,
  setFilterSearch,
  popFilterValue,
  setFilterRadio,
  setCarData,
  setSearchedData,
  setSearches,
  setLogoutClear,
} = landing.actions;
export default landing.reducer;
