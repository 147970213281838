// this file contain routes for route that are related to login and not found errors
import { lazy } from "react";

// project-imports
import GuestGuard from "utils/route-guard/GuestGuard";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
// import BlogList from "pages/Blogs/BlogList";
// import Blog from "pages/Blogs/BlogView";

// render - login
const BlogList = Loadable(lazy(() => import("pages/Blogs/BlogList")));
const Blog = Loadable(lazy(() => import("pages/Blogs/BlogView")));
const Home = Loadable(lazy(() => import("pages/Home")));
const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));
const AdminLogin = Loadable(lazy(() => import("pages/auth/adminLogin")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/register")));
// const Consultant = Loadable(
//   lazy(() => import("pages/consultant/ConsultantLanding"))
// );
const PrivacyPolicy = Loadable(
  lazy(() => import("pages/privacyPolicy/privacyPolicy"))
);
const TermsAndConditions = Loadable(
  lazy(() => import("pages/termsAndConditions/termsAndConditions"))
);
const Disclaimer = Loadable(lazy(() => import("pages/Disclaimer/Disclaimer")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/auth/forgot-password"))
);
const AuthCheckMail = Loadable(lazy(() => import("pages/auth/check-mail")));
const AuthResetPassword = Loadable(
  lazy(() => import("pages/auth/reset-password"))
);
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction"))
);
const AuthCodeVerification = Loadable(
  lazy(() => import("pages/auth/code-verification"))
);
const AdminCodeVerification = Loadable(
  lazy(() => import("pages/auth/admin-code-verification"))
);
const WelcomeScreen = Loadable(lazy(() => import("pages/maintenance/welcome")));

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    { path: "", element: <Home /> },
    { path: "privacypolicy", element: <PrivacyPolicy /> },
    { path: "termsanddconditions", element: <TermsAndConditions /> },
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        // {
        //   path: "/",
        //   element: location?.hostname?.split(".")[0] === 'admin' ? <AuthForgotPassword /> :<AuthLogin />,
        // },

        {
          path: "login",
          element:
            location?.hostname?.split(".")[0] === "admin" ? (
              <AdminLogin />
            ) : (
              <AuthLogin />
            ),
        },
        {
          path: "register",
          element: <AuthLogin />,
        },
        {
          path: "signup",
          element: <AuthRegister />,
        },
        {
          path: "signup:userId",
          element: <AuthRegister />,
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword />,
        },
        // {
        //   path: "check-mail",
        //   element: <AuthCheckMail />,
        // },
        {
          path: "reset-password",
          element: <AuthResetPassword />,
        },
        {
          path: "check-mail",
          element: <AuthCheckMail />,
        },
        {
          path: "code-verification",
          element:
            location?.hostname?.split(".")[0] === "admin" ? (
              <AdminCodeVerification />
            ) : (
              <AuthCodeVerification />
            ),
        },
        {
          path: "code-verification:email",
          element: <AuthCodeVerification />,
        },
        { path: "welcome", element: <WelcomeScreen /> },
        { path: "privacypolicy", element: <PrivacyPolicy /> },
        { path: "termsandconditions", element: <TermsAndConditions /> },
        { path: "disclaimer", element: <Disclaimer/> },
        { path: "bloglist", element: <BlogList /> },
        // { path: "blog", children: [{ path: "*", element: <Blog /> }] },
        { path: "blog/:slug", element: <Blog /> },
        {
          path: "maintenance",
          element: <CommonLayout />,
          children: [
            {
              path: "404",
              element: <MaintenanceError />,
            },
            {
              path: "500",
              element: <MaintenanceError500 />,
            },
            {
              path: "under-construction",
              element: <MaintenanceUnderConstruction />,
            },
            {
              path: "coming-soon",
              element: <MaintenanceComingSoon />,
            },
          ],
        },

        { path: "welcome", element: <WelcomeScreen /> },
      ],
    },
  ],
};

export default LoginRoutes;
