import { useRoutes } from "react-router-dom";
import events from "../utils/events";
// project-imports
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import AdminRoutes from "./AdminRoutes";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import { dispatch, useSelector } from "store";
import {
  resetActiveChannel,
  resetActiveChannelMessages,
  setActiveChannelMessagesStart,
  setActiveChannelRoomId,
  setActiveChannelUser,
  setChatLoading,
  setChatSkip,
  setCurrentRecieverId,
  setReceiverSocketId,
  setTotalChatCount,
  setUserInteracted,
  setUserSwitching,
} from "store/reducers/chat";
import { setChatsInit } from "utils/helper";

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  const { socket, user } = useAuth();
  // const currentReciever = useSelector(
  //   (state) => state?.chat?.currentReceiverId
  // );
  const updateChats = (chats: any) => {
    // console.log("called", skip);
    const newChats = chats?.fetchedChats?.chatHistory
      ? [...chats?.fetchedChats?.chatHistory]
      : [];
    dispatch(setActiveChannelMessagesStart(newChats.reverse()));
    dispatch(setTotalChatCount(chats?.fetchedChats?.count));
    dispatch(
      setCurrentRecieverId(chats?.fetchedChats?.receiverUser?.receiverId)
    );
    dispatch(setUserInteracted(false));
    localStorage.setItem(
      "recieverId",
      chats?.fetchedChats?.receiverUser?.receiverId
    );
    dispatch(setReceiverSocketId(chats?.fetchedChats?.receiverUser?.socketId));
    if (user?.email !== chats?.fetchedChats?.receiverUser?.email) {
      dispatch(setActiveChannelUser(chats?.fetchedChats?.receiverUser));
    }
    dispatch(setUserSwitching(false));
    if (newChats.length > 0) {
      dispatch(setChatLoading(false));
    }
    else{
      dispatch(setTotalChatCount(newChats.length));
      dispatch(setChatLoading(false));
    }
  };
  const setRoomId = (data: any) => {
    dispatch(setActiveChannelRoomId(data?.roomId));
    dispatch(setChatSkip(1));
    setChatsInit(data?.roomId, 1, socket, user);
  };
  useEffect(() => {
    const handleUnload = () => {
      if (user?.type && user?.type !== "admin")
        socket.emit(events.LOGOUT, { nickName: user?._id });
        dispatch(resetActiveChannelMessages([]));
        dispatch(resetActiveChannel());
    };
    if (socket && user?.type && user?.type !== "admin") {
      socket.on(events.INIT_CHATS, updateChats);
      socket.on(events.ROOM_ID, setRoomId);
      window.addEventListener("beforeunload", handleUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [socket]);

  return useRoutes([LoginRoutes, MainRoutes, AdminRoutes]);
}
