// third-party
import { FormattedMessage } from "react-intl";

// assets
import { StatusUp } from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  icon1: StatusUp,
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const subscriptions: NavItemType = {
  id: "group-subscriptions",
  type: "group",
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: "subscriptions",
      title: <FormattedMessage id="Subscriptions" />,
      type: "item",
      url: "/subscriptions",
    },
  ],
};

export default subscriptions;
