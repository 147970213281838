// third-party
import { createSlice } from "@reduxjs/toolkit";

// project-imports
import axios from "axios";
import { dispatch } from "../index";

// types
import { ChatStateProps } from "types/chat";

const initialState: ChatStateProps = {
  error: null,
  updateChat: 0,
  chats: [],
  skip: 1,
  newUsers: [],
  drawerOpen: true,
  isRead: false,
  chatIdeal: false,
  currentReceiverId: "",
  chatLoading: false,
  search: "",
  totalChatCount: 0,
  updateChat: 0,
  downScroll: 1,
  userInteracted: false,
  userSwitch: true,
  firstLoad: true,
  receiverSocketId: "",
  programmaticScroll: false,
  drawerSearchLoad: false,
  stopDrawerLoad: false,
  reload: false,
  drawerLoad: true,
  drawerSkip: 1,
  activeChanel: {
    description: "",
    user: { _id: "" },
    messages: [],
    msgCount: 0,
    dummySkip: 1,
    roomId: "",
    name: "",
    type: "",
    isTyping: false,
  },
  user: {},
  users: [],
};

// ==============================|| SLICE - CHAT ||============================== //

const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    setDrawerOpen(state, action) {
      state.drawerOpen = action.payload;
    },
    setReload(state, action) {
      state.reload = action.payload;
    },
    setDrawerSearchLoad(state, action) {
      state.drawerSearchLoad = action.payload;
    },
    setChatIdeal(state, action) {
      state.chatIdeal = action.payload;
    },
    setStopDrawerLoad(state, action) {
      state.stopDrawerLoad = action.payload;
    },
    resetUnreadCount(state, action) {
      let chats = state.chats;
      let updatedChats = chats.map((chat) => {
        if (chat?.chatRoomId === action.payload) {
          return {
            ...chat,
            unReadMessageCount: 0,
          };
        }
        return chat;
      });
      state.chats = updatedChats;
    },
    setChatDetails(state, action) {
      let chats = state.chats;

      let updatedChats = chats.map((chat) => {
        if (
          chat?.chatRoomId === action.payload.roomId &&
          action.payload.messageId !== chat?.lastChatMessage?.messageId
        ) {
          let unreadcount = 0;
          if (state.activeChanel.roomId != action.payload.roomId) {
            unreadcount = chat?.unReadMessageCount + 1;
          }

          return {
            ...chat,
            unReadMessageCount: unreadcount,
            lastChatMessage: {
              ...chat?.lastChatMessage,
              messageId: action.payload.messageId,
              message: action.payload.message,
              postedByUser: action.payload.senderId,
            },
          };
        }
        return chat;
      });
      const index = updatedChats.findIndex(
        (chat) => chat?.chatRoomId === action.payload.roomId
      );

      // Remove the chat object at the index and store it in a variable
      if (index && index !== -1) {
        console.log(index, "this is dummy index");
        const movedChat = updatedChats.splice(index, 1)[0];

        // Insert the moved chat object at the beginning of the array
        updatedChats.unshift(movedChat);

        state.chats = updatedChats;
        state.chats = updatedChats;
      } else {
        state.updateChat = state.updateChat + 1;
      }
    },
    setDrawerSkip(state, action) {
      state.drawerSkip = action.payload;
    },
    setDrawerLoad(state, action) {
      state.drawerLoad = action.payload;
    },
    setUserInteracted(state, action) {
      state.userInteracted = action.payload;
    },
    setProgrammaticScroll(state, action) {
      state.programmaticScroll = action.payload;
    },
    resetActiveChannel(state) {
      state.activeChanel = {
        description: "",
        user: { _id: "" },
        messages: [],
        msgCount: 0,
        dummySkip: 1,
        roomId: "",
        name: "",
        type: "",
        isTyping: false,
      };
    },
    setDownScroll(state, action) {
      state.downScroll = state.downScroll + 1;
    },
    setActiveChannelUser(state, action) {
      state.activeChanel.user = action.payload;
    },
    setIsRead(state, action) {
      state.isRead = action.payload;
    },
    setFirstLoad(state, action) {
      state.firstLoad = action.payload;
    },
    setActiveChannelRoomId(state, action) {
      state.activeChanel.roomId = action.payload;
    },
    setUserSwitching(state, action) {
      state.userSwitch = action.payload;
    },
    setNewUsers(state, action) {
      state.newUsers = action.payload;
    },
    setCurrentRecieverId(state, action) {
      state.currentReceiverId = action.payload;
    },
    setChatUserSearch(state, action) {
      state.search = action.payload;
    },
    setChatLoading(state, action) {
      state.chatLoading = action.payload;
    },
    setReceiverSocketId(state, action) {
      state.receiverSocketId = action.payload;
    },
    setTotalChatCount(state, action) {
      state.totalChatCount = action.payload;
    },
    addChatCount(state) {
      state.totalChatCount = state.totalChatCount + 1;
    },
    setChatSkip(state, action) {
      state.skip = action.payload;
    },
    setActiveChannelMessages(state, action) {
      const messageIdExists = state?.activeChanel?.messages?.some(
        (channel: any) => channel?.id === action.payload?.id
      );

      if (!messageIdExists) {
        state.activeChanel.messages = [
          ...state.activeChanel.messages,
          action.payload,
        ];
        state.totalChatCount = state.totalChatCount + 1;
      }
    },
    resetActiveChannelMessages(state, action) {
      state.activeChanel.messages = action.payload;
    },
    setActiveChannelMessagesStart(state, action) {
      if (state.skip === 1 && state.activeChanel.messages.length === 0) {
        state.activeChanel.messages = action.payload;
      } else if (state.skip === 1 && state.activeChanel.messages.length !== 0) {
        // state.activeChanel.messages = action.payload;
      } else {
        if (state.activeChanel.dummySkip !== state.skip) {
          state.activeChanel.dummySkip = state.skip;
          state.activeChanel.messages = [
            ...action.payload,
            ...state.activeChanel.messages,
          ];
        }
      }
    },
    setActiveChannel(state, action) {
      state.activeChanel = action.payload;
    },
    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
    },
    // GET USER CHATS
    getUserChatsSuccess(state, action) {
      const newChats = action.payload;

      if (state.drawerSkip === 1) {
        // If drawerSkip is 1, replace the entire chats array with the new data
        state.chats = newChats;
      } else {
        // If drawerSkip is not 1, merge newChats with existing chats array
        newChats.forEach((newChat: any) => {
          // Check if the receiverId of the new chat already exists in state.chats
          const existingChatIndex = state.chats.findIndex(
            (chat: any) => chat.receiverId === newChat.receiverId
          );
          if (existingChatIndex === -1) {
            // If receiverId doesn't exist, add the new chat to the array
            state.chats.push(newChat);
          } else {
            // If receiverId already exists, update the existing chat
            state.chats[existingChatIndex] = newChat;
          }
        });
      }
    },
    getUserChatsAppendSuccess(state, action) {
      state.chats = [...state.chats, action.payload];
    },
    resetDummySkip(state, action) {
      state.activeChanel.dummySkip = action.payload;
    },
    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
    },
  },
});

export const {
  getUsersSuccess,
  setDrawerLoad,
  setTotalChatCount,
  setFirstLoad,
  getUserChatsAppendSuccess,
  setDrawerSkip,
  setStopDrawerLoad,
  setActiveChannelRoomId,
  setChatIdeal,
  setDownScroll,
  setChatUserSearch,
  setProgrammaticScroll,
  setReload, //this is just used for reloading chat when user is inactive make chat scroll to bottom
  addChatCount,
  resetActiveChannel,
  setIsRead,
  setNewUsers,
  setDrawerSearchLoad,
  setChatDetails,
  resetDummySkip,
  setUserSwitching,
  setCurrentRecieverId,
  getUserChatsSuccess,
  setReceiverSocketId,
  setUserInteracted,
  resetActiveChannelMessages,
  setChatLoading,
  getUserSuccess,
  setActiveChannelUser,
  setChatSkip,
  setActiveChannel,
  setActiveChannelMessagesStart,
  setActiveChannelMessages,
  resetUnreadCount,
  setDrawerOpen,
  hasError,
} = chat.actions;

export default chat.reducer;
