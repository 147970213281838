import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// project-imports
import useAuth from "hooks/useAuth";

// types
import { GuardProps } from "types/auth";
import { getUserById } from "services/auth.service";
import axios from "axios";
import { ADMIN_DEFAULT_PATH, APP_DEFAULT_PATH } from "config";
// import { dispatch } from "store";
import jwtDecode from "jwt-decode";
import { KeyedObject } from "types/root";

// const initialState: AuthProps = {
//   isLoggedIn: false,
//   isInitialized: false,
//   user: null,
//   socket: null,
// };

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, updateLogin, user, clearUser, socket } = useAuth();
  const navigate = useNavigate();
  const locationState = useLocation();
  const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
      localStorage.setItem("serviceToken", serviceToken);
      axios.defaults.headers.common.Authtoken = serviceToken;
    } else {
      localStorage.removeItem("serviceToken");
      delete axios.defaults.headers.common.Authtoken;
    }
  };

  const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
      return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
  };

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // console.log("TEST:-------")
        if (error?.response?.status === 401) {
          setSession();
          navigate("/login", {
            state: {
              unauthorized: true,
            },
            replace: true,
          });
        }
        return Promise.reject(error);
      }
    );
  });
  useEffect(() => {
    if (location?.hostname?.split(".")[0] === "admin" && isLoggedIn) {
      // console.log("1");
      if (user?.type === "admin") navigate(ADMIN_DEFAULT_PATH);
    } else if (
      locationState?.search?.split("=")[0] === "?id" &&
      locationState?.pathname === "/home"
    ) {
      // console.log("2");
      getUserById({ userId: locationState?.search?.split("=")[1] }).then(
        (res) => {
          if (res?.status === 200) {
            setSession(res?.data?.data?.loginToken);
            if (res?.status === 200) {
              updateLogin(true, res?.data?.data, socket);
              // const user = res.data.data;
              // dispatch({
              //   type: LOGIN,
              //   payload: {
              //     isLoggedIn: true,
              //     user,
              //   },
              // });
            }
            res?.data?.data?.loginToken
              ? navigate(APP_DEFAULT_PATH)
              : navigate("/login");
          }
        }
      );
    } else if (
      locationState?.search?.split("=")[0] === "?email" &&
      locationState?.pathname === "/code-verification"
    ) {
      localStorage.setItem(
        "resendPasscodeTime",
        addTimeToPasscode().toString()
      );
      navigate(`/code-verification`, {
        state: { email: locationState?.search?.split("=")[1] },
      });
    } else if (
      locationState?.search?.split("=")[0] === "?message" &&
      locationState?.pathname === "/error"
    ) {
      // console.log("3");
      navigate("/login", {
        state: { message: locationState?.search?.split("=")[1] },
      });
    } else if (locationState.pathname === "/home") {
      // console.log("4");
      if (localStorage.getItem("serviceToken")) {
        if (
          !locationState.pathname.startsWith("/myaccount") ||
          !locationState.pathname.startsWith("/customer") ||
          !locationState.pathname.startsWith("/view")
        ) {
          navigate("/home");
        }
      } else if (!localStorage.getItem("serviceToken")) {
        clearUser();
        navigate("/");
      } else {
        navigate("/");
      }
    } else if (locationState.pathname === "/") {
      // console.log("5");

      location?.hostname?.split(".")[0] === "admin"
        ? navigate("/login")
        : navigate("/");
    } else if (!isLoggedIn) {
      // console.log("6");
      navigate("/login");
    } else {
      // console.log("asdf asdf asdf asdf")
      // if (!localStorage.getItem("serviceToken")) {
      //   navigate("/login");
      // }
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default AuthGuard;
