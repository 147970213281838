// material-ui
import { Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const navigate = useNavigate();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      {location.pathname === "/login" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/code-verification" ? (
        <Toolbar
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            "& .logo-text": {
              textDecoration: "none",
              color: "#0A4A8A",
              fontFamily: "Passion One, sans-serif",
              fontSize: { xs: "20px", sm: "26px", md: "32px", lg: "32px" },
              fontWeight: 900,
              lineHeight: "70px",
              userSelect: "none",
              pointerEvents: "none",
            },
          }}
        >
          <a onClick={() => navigate("/login")}>
            <Typography variant="h4" className="logo-text">
              AUTOMATCHPROS
            </Typography>
          </a>
        </Toolbar>
      ) : (
        <Typography
          variant="h4"
          sx={{ color: "#0A4A8A", cursor: "pointer", fontWeight: 700 }}
        >
          AUTOMATCHPROS
        </Typography>
      )}
    </>
  );
};

export default LogoMain;
