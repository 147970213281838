// third-party
import { FormattedMessage } from "react-intl";

// assets
import { Setting2 } from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  icon1: Setting2,
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const settings: NavItemType = {
  id: "group-settings",
  type: "group",
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: "settings",
      title: <FormattedMessage id="Settings" />,
      type: "item",
      url: "/settings",
    },
  ],
};

export default settings;
