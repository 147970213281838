import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptions: [],
  adminSearch: "",
  pageIndex: 1,
  pageSize: 10,
  count: 0,
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscription(state, action) {
      state.subscriptions = action.payload;
    },
    deleteSubscription(state, action) {
      const data = state.subscriptions.filter(
        (data) => data?._id !== action.payload
      );
      state.subscriptions = data;
    },
    setSubscriptionsPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setSubscriptionsCount(state, action) {
      state.count = action.payload;
    },
    setSubscriptionsPageSize(state, action) {
      state.count = action.payload;
    },
    setAdminSearch(state, action) {
      state.adminSearch = action.payload;
    },
  },
});

export const {
  setSubscription,
  setSubscriptionsCount,
  setSubscriptionsPageSize,
  setSubscriptionsPageIndex,
  setAdminSearch,
  deleteSubscription,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
