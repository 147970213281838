import {
  Grid,
  Badge,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  DialogContent,
  Avatar,
  CardContent,
  Button,
  Tooltip,
} from "@mui/material";
import { Add, Location, Message } from "iconsax-react";
import { useEffect, useState } from "react";
import {
  getAdminUserDetails,
  getUserById,
  sendFeedback,
} from "services/user.service";
import Snackbar from "utils/Snackbar";
import useAuth from "hooks/useAuth";
import GeneralSkeleton from "utils/GeneralSkeleton";
import { currenyFormat, getRoomId } from "utils/helper";
import {
  resetActiveChannelMessages,
  setActiveChannelUser,
  setChatIdeal,
  setChatSkip,
  setCurrentRecieverId,
  setTotalChatCount,
  setUserSwitching,
} from "store/reducers/chat";
import { dispatch } from "store";
import { useNavigate } from "react-router";
import { setSettingCount } from "store/reducers/user";

const CardContainer = ({ data }: { data: any }) => {
  const insertSpaceBeforeUppercase = (str: string) => {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  return (
    <Grid container>
      {Object.entries(data).map(([key, value]: any) => (
        <Grid sm={key === "specifications" ? 12 : 4} xs={6} sx={{ mb: 1 }}>
          <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
            {insertSpaceBeforeUppercase(key)}
          </Typography>
          <Typography variant="h5">
            {Array.isArray(value) && value.length > 0 ? (
              <>
                <Tooltip title={value.toString()}>
                  <span>
                    {value.toString().length > 18
                      ? `${value.toString().slice(0, 18)}...`
                      : value.toString()}
                  </span>
                </Tooltip>
              </>
            ) : key === "price" ? (
              currenyFormat(value)
            ) : Array.isArray(value) && value.length === 0 ? (
              "NA"
            ) : (
              <>
                {value ? (
                  <>
                    <Tooltip title={value}>
                      <span>
                        {value && value.length > 18 ? (
                          <>{`${value.slice(0, 18)}...`}</>
                        ) : (
                          value
                        )}
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  "NA"
                )}
              </>
            )}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const BuyerLoader = () => {
  return (
    <Grid container>
      <Grid lg={4} md={4} sm={4} xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GeneralSkeleton height={"173"} width={"160"} />
        </Box>
      </Grid>
      <Grid
        lg={8}
        md={8}
        sm={8}
        xs={12}
        sx={{
          borderRadius: "10px",
          border: "1px solid #C8C8C8",
          p: 2,
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              textTransform: "capitalize",
            }}
            gutterBottom
          >
            <GeneralSkeleton height={"26"} width={"170"} />
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
            gutterBottom
          >
            <GeneralSkeleton height={"26"} width={"140"} />
          </Typography>
          {/* <Location size={22} variant="Bold" /> */}
          {/* Texas, USA  */}
          {/* {`${user?.postalCode || ""} ${user?.location || ""}`} */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              my: 1,
            }}
          >
            <GeneralSkeleton height={"26"} width={"140"} />
          </Box>
        </Box>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12} sx={{ p: 0 }}>
        <Box
          sx={{
            my: 1,
            border: "1px solid #C8C8C8",
            borderRadius: "10px",
          }}
        >
          <GeneralSkeleton height={"113"} width={"530"} />
        </Box>
      </Grid>
    </Grid>
  );
};

const BuyerModal = ({
  id,
  handleClose,
  open,
}: {
  id: string;
  handleClose: any;
  open: boolean;
}) => {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState(true);
  const data = useAuth();
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const userDetails = data?.user;

  const handleModalClose = () => {
    setUser(null);
    handleClose();
    setLoading(true);
    setFeedbackStatus(false);
  };
  useEffect(() => {
    if (open) {
      if (userDetails?.type === "admin") {
        getAdminUserDetails({ userId: id })
          .then((user) => {
            // console.log(user);
            setUser(user?.data?.data?.users);
            setLoading(false);
          })
          .catch((err) => {
            Snackbar(err?.response?.data?.message, "error");
            setLoading(false);
          });
      } else {
        getUserById({ userId: id })
          .then((user: any) => {
            // console.log("data is", user.data.data);
            setUser(user?.data?.data);
            setLoading(false);

            //   setPoints(user?.data?.data);
          })
          .catch((error) => {
            setLoading(false);
            handleModalClose();
            Snackbar(
              error.response.data.message
                ? error.response.data.message
                : "something went wrong.",
              "error"
            );
            // console.log(error);
          });
      }
    }
  }, [open]);
  //   console.log(user?.imageId, userDetails?.type === "admin");
  const navigate = useNavigate();
  const sendFeedbackrequest = () => {
    sendFeedback({ buyerId: user?._id })
      .then((response) => {
        setFeedbackStatus(true);
        Snackbar(response?.data?.message, "success");
        handleModalClose();
      })
      .catch((error) => {
        Snackbar(error?.response?.data?.message, "error");
      });
  };
  return (
    <Box>
      <Dialog
        fullWidth={false}
        maxWidth={"sm"}
        open={open}
        onClose={handleModalClose}
      >
        <Box sx={{ mx: 1, overflowX: "none", maxWidth: "600px" }}>
          <DialogTitle
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 9999999999,
              backgroundColor: "#ffffff",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "600" }}>
              Buyer Details
            </Typography>
            <Badge
              sx={{
                position: "absolute",
                top: 28,
                right: 34,
                backgroundColor: "#EAEAEA",
              }}
              badgeContent={
                <Box>
                  <IconButton
                    onClick={handleModalClose}
                    sx={{
                      transform: "rotate(45deg)",
                      borderRadius: "50%",
                      width: "48px", // Adjust the width to make it bigger
                      height: "48px", // Adjust the height to make it bigger
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)", // Change the color on hover
                      },
                    }}
                    size="large" // Adjust the size to make it bigger
                  >
                    <Add fontSize="inherit" />
                  </IconButton>
                </Box>
              }
            />
          </DialogTitle>
          <DialogContent sx={{ maxHeight: "70vh", overflowY: "auto" }}>
            {loading ? (
              <Box>
                <BuyerLoader />
              </Box>
            ) : (
              <Grid container>
                <Grid lg={4} md={4} sm={4} xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {user?.imageId?.mimetype || user?.image?.mimetype ? (
                      <Box
                        sx={{
                          width: "160px",
                          height: "173px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            user?.imageId?.mimetype
                              ? user?.imageId?.mimetype &&
                                `data:${user?.imageId?.mimetype};base64,${user?.imageId?.file}`
                              : (user?.image?.mimetype &&
                                  `data:${user?.image?.mimetype};base64,${user?.image?.file}`) ||
                                ""
                          }
                          style={{
                            width: "210px",
                            height: "213px",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                          alt=""
                        />
                      </Box>
                    ) : (
                      <Avatar
                        sx={{
                          width: "160px",
                          height: "173px",
                          borderRadius: { xs: "0px", sm: "1rem" },
                          "& img": {
                            width: "auto",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "inherit",
                            maxHeight: "270px",
                          },
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid
                  lg={8}
                  md={8}
                  sm={8}
                  xs={12}
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid #C8C8C8",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      textTransform: "capitalize",
                    }}
                    gutterBottom
                  >{`${user?.firstName} ${user?.lastName}`}</Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                    gutterBottom
                  >
                    {(user?.location || user?.postalCode) && (
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Location size="22" variant="Bold" />
                        {`${user?.location ? user?.location : ""} ${
                          user?.postalCode
                        } `}
                      </Typography>
                    )}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      sx={{
                        backgroundColor: "#0A4A8A",
                        color: "white",
                        "&:hover": { backgroundColor: "#0A4A8A" },
                      }}
                      variant="contained"
                      // onClick={() => {
                      //   navigate("/myaccount", {
                      //     state: { userId: user?._id },
                      //   });
                      // }}

                      startIcon={<Message />}
                      onClick={() => {
                        dispatch(resetActiveChannelMessages([]));
                        dispatch(setUserSwitching(true));
                        dispatch(setChatIdeal(false));
                        dispatch(setChatSkip(1));
                        dispatch(setTotalChatCount(0));
                        getRoomId(data, user);
                        dispatch(setActiveChannelUser(user));
                        localStorage.setItem("recieverId", user?._id);
                        dispatch(setCurrentRecieverId(user?._id));
                        navigate("/myaccount", { state: { userData: user } });
                        dispatch(setSettingCount(2));
                        handleModalClose();
                      }}
                      focusRipple
                    >
                      Message
                    </Button>
                    <Button
                      sx={{
                        color: "#0A4A8A",
                        border: "1px solid #0A4A8A",
                        borderRadius: "10px",
                        "&:hover": { color: "#0A4A8A" },
                        width: "auto",
                        mx: 1,
                      }}
                      disabled={
                        feedbackStatus ||
                        user?.requestFeedbackSellerId?.includes(data?.user?._id)
                      }
                      onClick={sendFeedbackrequest}
                      variant="outlined"
                      focusRipple
                    >
                      Request For Feedback
                    </Button>
                  </Box>
                  {/* <Location size={22} variant="Bold" /> */}
                  {/* Texas, USA  */}
                  {/* {`${user?.postalCode || ""} ${user?.location || ""}`} */}
                </Grid>
                <Grid lg={12} md={12} sm={12} xs={12} sx={{ p: 0 }}>
                  {user?.carPreferences?.length > 0 ? (
                    user?.carPreferences?.map((point: any, index: number) => (
                      <Box
                        sx={{
                          my: 1,
                          border: "1px solid #C8C8C8",
                          borderRadius: "10px",
                        }}
                      >
                        <Box sx={{ mt: 1 }}>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "900", ml: 2 }}
                          >
                            Car Preferences {index + 1}
                          </Typography>
                          <CardContent>
                            <CardContainer data={point} />
                          </CardContent>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        my: 1,
                        border: "1px solid #C8C8C8",
                        borderRadius: "10px",
                      }}
                    >
                      <Box sx={{ mt: 1 }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "900", ml: 2 }}
                        >
                          Car preferences
                        </Typography>
                        <CardContent>
                          <Typography variant="h4">
                            No Car Prefrences Yet
                          </Typography>
                        </CardContent>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default BuyerModal;
