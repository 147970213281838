import { dispatch } from "store";
import { closeSnackbar, openSnackbar } from "store/reducers/snackbar";

const Snackbar = (message: String, color: String) => {
  if (message !== "Please Provide header  token") {
    dispatch(
      openSnackbar({
        open: true,
        message: message,
        forceAutoHide: color !== "success" ? false : true,
        variant: "alert",
        alert: {
          color: color,
        },
        anchorOrigin: { vertical: "top", horizontal: "center" },
        close: color === "success" ? false : true,
      })
    );
    if (color !== "success") {
      setTimeout(() => {
        dispatch(closeSnackbar());
      }, 9000);
    }
  }
};

export default Snackbar;
