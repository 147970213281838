import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  carValue: number;
  editValue: any;
  cars: Array<any>;
  search: string;
  skip: number;
  limit: number;
  loading: boolean;
  count: number;
} = {
  carValue: 0,
  editValue: {},
  cars: [],
  search: "",
  skip: 1,
  limit: 9,
  loading: true,
  count: 0,
};

const myAccount = createSlice({
  name: "myAccount",
  initialState,
  reducers: {
    setCarSettingValue(state, action) {
      state.carValue = action.payload;
    },
    setEditValue(state, action) {
      state.editValue = action.payload;
    },
    setCarValue(state, action) {
      state.cars = action.payload;
    },
    setConsultantSearch(state, action) {
      state.search = action.payload;
    },
    setConsultantCount(state, action) {
      state.count = action.payload;
    },
    setConsultantSkip(state, action) {
      state.skip = action.payload;
    },
    setConsultantLoading(state, action) {
      state.loading = action.payload;
    },
    setConsultantLimit(state, action) {
      state.limit = action.payload;
    },
    deleteFilterCar(state, action) {
      const data = state.cars.filter((car) => car._id !== action.payload);
      state.cars = data;
    },
  },
});

export const {
  setCarSettingValue,
  deleteFilterCar,
  setCarValue,
  setConsultantCount,
  setEditValue,
  setConsultantSearch,
  setConsultantLoading,
  setConsultantSkip,
  setConsultantLimit,
} = myAccount.actions;
export default myAccount.reducer;
