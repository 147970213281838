import { useRef, useState, ReactNode, SyntheticEvent } from "react";
import { useNavigate } from "react-router";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// project-imports
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import useAuth from "hooks/useAuth";

// assets
import avatar1 from "assets/images/users/avatar-6.png";
import { Logout, Profile } from "iconsax-react";
import { resetActiveChannel, resetActiveChannelMessages } from "store/reducers/chat";
import { dispatch } from "store";
// import ProfileTab from './ProfileTab';

// types
interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

// tab panel wrapper
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      sx={{ p: 1 }}
    >
      {value === index && children}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { logout, user,socket } = useAuth();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<Number>();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleListItemClick = (event: any, index: number) => {
    setSelectedIndex(index);
  };
  const handleLogout = async () => {
    try {
      logout(user?._id);
      socket.emit(events.LOGOUT, {nickName:user?._id});
      dispatch(resetActiveChannelMessages([]));
      dispatch(resetActiveChannel());
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          borderRadius: 1,
          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark"
                ? "secondary.light"
                : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar alt="profile user" src={avatar1} />
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250,
                },
                borderRadius: 1.5,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={1.25}
                          alignItems="center"
                        >
                          <Avatar alt="profile user" src={avatar1} />
                          <Stack>
                            <Typography variant="subtitle1">
                              {user?.name}
                            </Typography>
                            <Typography variant="body1" color="secondary">
                              Admin
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <List
                      component="nav"
                      sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
                    >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event: any) => {
                          navigate("/settings");
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <Profile variant="Bulk" size={18} />
                        </ListItemIcon>
                        <ListItemText primary="Account Settings" />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 2}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <Logout variant="Bulk" size={18} />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfilePage;
