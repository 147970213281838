// project-imports
import services from "utils/mockAdapter";

// types
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

const dashboard: NavItemType = {
  id: "group-dashboard",
  title: " ",
  type: "group",
  icon: "navigation",
  children: [
    {
      id: "default",
      title: "dashboard",
      type: "item",
      url: "/dashboard",
      icon: "dashboard",
      breadcrumbs: false,
    },
  ],
};

// ==============================|| DASHBOARD - GET ||============================== //

services.onGet("/api/dashboard").reply(200, { dashboard: dashboard });


