import Loader2 from "components/Loader2";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const GreyLoader = () => {
  const loading = useSelector((state: any) => state?.user)?.greyLoader;

  return (
    <Box>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Grey background with some transparency
            display: loading ? "flex" : "none", // Show/hide based on the loading prop
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure it appears above other elements
          }}
        >
          <Loader2 />
        </div>
      )}
    </Box>
  );
};

export default GreyLoader;
