import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL + "notification";

// api's with base url /notification are called here

export const getAllNotification = () => {
  return axios.get(`${API_URL}/all`, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const getSingleNotification = (reqData: Object) => {
  return axios.get(`${API_URL}`, {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const readAllNotification = () => {
  return axios.put(`${API_URL}/readAll`, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const readSingleNotification = (reqData: Object) => {
  return axios.put(`${API_URL}/read`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
