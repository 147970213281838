// material-ui
// import { useTheme } from "@mui/material/styles";
import Avatar from "components/@extended/Avatar";
import { useSelector } from "react-redux";
import favicon from "images/favicon.png";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const data = useSelector((state: any) => state?.homepage?.data);
  const faviconImg = data?.icon?.file || favicon;
  return (
    <Avatar sx={{ cursor: "pointer" }} src={faviconImg} alt="automatchpros" />
  );
};

export default LogoIcon;
