import { createContext, useEffect, useReducer, ReactElement } from "react";
import io from "socket.io-client";
import events from "../utils/events";
// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project-imports
import Loader from "components/Loader";
import axios from "utils/axios";
import { KeyedObject } from "types/root";
import { AuthProps, JWTContextType } from "types/auth";
import {
  adminLoginService,
  getUserById,
  loginService,
  logoutService,
} from "services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "utils/Snackbar";
import { getUserByToken } from "services/user.service";
import { addTimeToPasscode, setUserAndEmit } from "utils/helper";
import { setLogoutClear } from "store/reducers/landing";
import store from "store";
import { setGreyLoader } from "store/reducers/user";
// import useAuth from "hooks/useAuth";
const chance = new Chance();

const socketUrl = process.env.REACT_APP_SOCKET_CONNECTION_URL;

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authtoken = serviceToken;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authtoken;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  // const { socket } = useAuth();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const locationState = useLocation();
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem("serviceToken");
        if (serviceToken) {
          setSession(serviceToken);
          getUserByToken()
            .then((res) => {
              const user = res.data.data;
              if (user?.type !== "admin" && socketUrl) {
                let socket = io(socketUrl, {
                  transports: ["polling", "websocket"],
                  secure: true,
                });

                let userData: {
                  socketId: string;
                  name: string;
                  nickName: string;
                } = {
                  socketId: "",
                  name: "",
                  nickName: "",
                };
                socket.on("connect", () => {
                  console.log("Connected");

                  userData.socketId = socket?.id;
                  userData.name = user?.firstName;
                  userData.nickName = user?._id;
                  socket?.emit(events.IS_USER, userData);
                  setUserAndEmit(user, socket);
                  dispatch({
                    type: LOGIN,
                    payload: {
                      isLoggedIn: true,
                      user,
                      socket,
                    },
                  });
                });
                socket.on("error", (error) => {
                  console.error("Socket error:", error);
                });
                socket.on("disconnect", () => {
                  console.log("Socket disconnected");
                });
              } else {
                dispatch({
                  type: LOGIN,
                  payload: {
                    isLoggedIn: true,
                    user,
                  },
                });
              }
            })
            .catch((err) => {
              if (err?.response?.status !== 200) {
                setSession();
                if (locationState.pathname !== "/") {
                  window.location.href = "/login";
                  // navigate("/login",{state:{from:'/login'},replace:true});
                  // navigate("/login",{state:{from:'/login'},replace:true});
                } else {
                  window.location.href = "/";
                  // navigate("/",{state:{from:'/'},replace:true});
                }
              }
            });
        } else if (location?.pathname === "welcome") {
          navigate("/welcome");
        } else {
          // navigate("/login");
          dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
        }
      } catch (err) {
        console.error(err);
        // navigate("/login");
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, isRemembered?: boolean) => {
    loginService(isRemembered ? { email, isRemembered } : { email })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.data?.loginToken) {
            Snackbar(res?.data?.message, "success");
            const user = res.data.data;
            // console.log('login status true')
            const serviceToken = res.data.data.loginToken;
            setSession(serviceToken);
            // let socket = io(socketUrl, { transports: ["polling", "websocket"] });
            // let userData: {
            //   socketId: string | undefined;
            //   name: string;
            //   nickName: string;
            // } = {
            //   socketId: "",
            //   name: "",
            //   nickName: "",
            // };
            // socket.emit( events.NEW_USER, user )
            // socket.on("connect", () => {
            //   userData.socketId = socket?.id;
            //   userData.name = user?.firstName;
            //   (userData.nickName = user?._id),
            //     socket.emit(events.IS_USER, userData);
            //   console.log(socket, "socket init");
            //   // console.log("Connected");
            // });
            // console.log(socket, "socket init");
            updateLogin(true, user);
            // dispatch({
            //   type: LOGIN,
            //   payload: {
            //     isLoggedIn: true,
            //     user,
            //     socket,
            //   },
            // });
            navigate("/welcome");
          } else {
            Snackbar(res.data.message, "success");
            localStorage.setItem(
              "resendPasscodeTime",
              addTimeToPasscode().toString()
            );
            navigate("/code-verification", {
              state: { email },
            });
          }
        } else {
          Snackbar(res?.data.message, "error");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400)
          Snackbar(err?.response?.data.message, "error");
        else {
          Snackbar(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "something went wrong.",
            "error"
          );
          navigate("/");
        }
      });
  };
  const adminLogin = async (email: string, password: string) => {
    adminLoginService({ email, password, type: "admin" })
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.data?.type === "admin") {
            Snackbar(res?.data?.message, "success");
            const user = res.data.data;
            const serviceToken = res.data.data.loginToken;
            setSession(serviceToken);
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user,
              },
            });
            navigate("/dashboard");
          }
        } else {
          Snackbar(res.data.message, "error");
        }
      })
      .catch((err) => {
        if (err.response.status === 400)
          Snackbar(err?.response?.data?.message, "error");
        else
          Snackbar(
            err.response.data.message
              ? err.response.data.message
              : "something went wrong.",
            "error"
          );
      });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = (userId: string) => {
    store.dispatch(setGreyLoader(true));
    logoutService({ userId }).then((res) => {
      if (res.status === 200) {
        setSession(null);
        localStorage.removeItem("settingIndexval");
        localStorage.removeItem("serviceToken");
        store.dispatch(setLogoutClear());
        dispatch({ type: LOGOUT });
        Snackbar(res?.data?.message, "success");
        navigate("/login");
      } else {
        Snackbar(res.data.message, "error");
      }
      store.dispatch(setGreyLoader(false));
    });
  };
  const clearUser = () => {
    dispatch({ type: LOGOUT });
  };
  const resetPassword = async (email: string) => {};

  const updateLogin = (isLoggedIn: boolean, user: any, socket?: any) => {
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn,
        user,
        socket,
      },
    });
  };

  if (
    locationState.pathname !== "/" &&
    state.isInitialized !== undefined &&
    !state.isInitialized
  ) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        adminLogin,
        logout,
        register,
        clearUser,
        resetPassword,
        updateLogin,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
