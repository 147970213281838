import { useEffect, useReducer } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// project-imports
import { ADMIN_DEFAULT_PATH, APP_DEFAULT_PATH } from "config";
import useAuth from "hooks/useAuth";

// types
import { AuthProps, GuardProps } from "types/auth";
import { getUserById } from "services/auth.service";
import axios from "axios";
import { getUserByToken } from "services/user.service";
// import { dispatch } from "store";
import { KeyedObject } from "types/root";
import jwtDecode from "jwt-decode";
import { LOGIN } from "store/reducers/actions";
import { addTimeToPasscode } from "utils/helper";
import authReducer from "store/reducers/auth";
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const locationState = useLocation();
  const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
      localStorage.setItem("serviceToken", serviceToken);
      axios.defaults.headers.common.Authtoken = serviceToken;
    } else {
      localStorage.removeItem("serviceToken");
      delete axios.defaults.headers.common.Authtoken;
    }
  };
  const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
      return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
  };
  const { slug } = useParams();
  useEffect(() => {
    // console.log("guest guard:- ", locationState?.pathname)

    if (locationState?.pathname === "/privacypolicy") {
      navigate("/privacypolicy");
    } else if (locationState?.pathname === "/termsandconditions") {
      navigate("/termsandconditions");
    } else if (locationState?.pathname === "/disclaimer") {
      navigate("/disclaimer");
    } else if (locationState?.pathname === "/bloglist") {
      navigate("/bloglist");
    } else if (locationState?.pathname.startsWith("/blog")) {
      navigate(`/blog/${slug}`);
    } else if (isLoggedIn && localStorage.getItem("serviceToken")) {
      // console.log(locationState?.pathname);
      navigate(
        locationState?.state?.from
          ? locationState?.state?.from
          : location?.hostname?.split(".")[0] === "admin"
          ? ADMIN_DEFAULT_PATH
          : APP_DEFAULT_PATH,
        {
          state: {
            from: "",
          },
          replace: true,
        }
      );
    } else if (localStorage.getItem("serviceToken")) {
      const serviceToken = localStorage.getItem("serviceToken");

      if (serviceToken) {
        setSession(serviceToken);
        getUserByToken()
          .then((res) => {
            if (res.status === 200) {
              const user = res.data.data;
              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: true,
                  user,
                },
              });
              navigate(
                location?.hostname?.split(".")[0] === "admin"
                  ? ADMIN_DEFAULT_PATH
                  : APP_DEFAULT_PATH
              );
            } else {
              window.location.href = "/login";
              // navigate("/login");
              // navigate("/login",{state:{from:'/login'},replace:true});
              // Snackbar(res?.data?.message, "error");
            }
          })
          .catch((err) => {
            window.location.href = "/login";
            // if (err.response.status == 401) {
            // Snackbar("something went", "error");
            // navigate("/login",{state:{from:'/login'},replace:true});
            // } else
          });
      }
    } else if (
      locationState?.search?.split("=")[0] === "?userId" &&
      locationState?.pathname === "/signup"
    ) {
      navigate(`/signup${locationState?.search}`);
    } else if (
      locationState?.search?.split("=")[0] === "?email" &&
      locationState?.pathname === "/code-verification"
    ) {
      localStorage.setItem(
        "resendPasscodeTime",
        addTimeToPasscode().toString()
      );
      navigate(`/code-verification`, {
        state: { email: locationState?.search?.split("=")[1] },
      });
    } else if (
      locationState?.search?.split("=")[0] === "?id" &&
      locationState?.pathname === "/home"
    ) {
      getUserById({ userId: locationState?.search?.split("=")[1] }).then(
        (res) => {
          if (res?.status === 200) {
            setSession(res?.data?.data?.loginToken);
            res?.data?.data?.loginToken
              ? navigate(APP_DEFAULT_PATH)
              : navigate("/login");
          }
        }
      );
    } else {
      // navigate("/login",{state:{from:'/login'},replace:true});
      // dispatch({ type: LOGOUT });
    }
  }, [isLoggedIn]);

  return children;
};

export default GuestGuard;
