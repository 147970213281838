// this file contains routes for admin panel
import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AdminGuard from "utils/route-guard/AdminGaurd";
// render - dashboard
const DashboardDefault = Loadable(
  lazy(() => import("pages/admin/dashboard/default"))
);
const UserList = Loadable(lazy(() => import("pages/admin/users/index")));
const Subscriptions = Loadable(
  lazy(() => import("pages/admin/subscriptions/subscriptionList"))
);
const SubscriptionForm = Loadable(
  lazy(() => import("pages/admin/subscriptions/SubscriptionForm"))
);
const AddUser = Loadable(lazy(() => import("pages/admin/users/addUser")));
// const PlansList = Loadable(lazy(() => import("pages/admin/plans/PlansList")));
// const AddPlans = Loadable(lazy(() => import("pages/admin/plans/AddPlans")));
const BlogForm = Loadable(lazy(() => import("pages/admin/blogs/BlogForm")));
const BlogList = Loadable(lazy(() => import("pages/admin/blogs/BlogList")));
const Settings = Loadable(lazy(() => import("pages/admin/settings/Settings")));
const ContentManagement = Loadable(
  lazy(() => import("pages/admin/cms/ContentManagement"))
);
const ContentManagementList = Loadable(
  lazy(() => import("pages/admin/cms/ContentManagementList"))
);
// const FeedbackForm = Loadable(
//   lazy(() => import("pages/admin/feedback/FeedbackForm"))
// );
// const FeedbackTable = Loadable(
//   lazy(() => import("pages/admin/feedback/FeedbackTable"))
// );
// const PlansView = Loadable(lazy(() => import("pages/admin/plans/PlansView")));

const AdminRoutes = {
  path: "/",
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: "dashboard",

      element: <DashboardDefault />,
    },
    {
      path: "users",
      children: [
        {
          path: "",
          element: <UserList />,
        },
        {
          path: "add",
          element: <AddUser />,
        },
      ],
    },
    {
      path: "subscriptions",
      children: [
        {
          path: "",
          element: <Subscriptions />,
        },
        {
          path: "add",
          element: <SubscriptionForm />,
        },
      ],
    },
    // {
    //   path: "plans",
    //   children: [
    //     {
    //       path: "",
    //       element: <PlansList />,
    //     },
    //     {
    //       path: "add",
    //       element: <AddPlans />,
    //     },
    //     {
    //       path: "view",
    //       element: <PlansView />,
    //     },
    //   ],
    // },
    {
      path: "cms",
      children: [
        {
          path: "",
          element: <ContentManagementList />,
        },
        {
          path: "add",
          element: <ContentManagement />,
        },
        {
          path: "edit",
          element: <ContentManagement />,
        },
      ],
    },
    // {
    //   path: "feedback",
    //   children: [
    //     {
    //       path: "",
    //       element: <FeedbackTable />,
    //     },
    //     {
    //       path: "add",
    //       element: <FeedbackForm />,
    //     },
    //   ],
    // },
    {
      path: "blogs",
      children: [
        {
          path: "",
          element: <BlogList />,
        },
        {
          path: "add",
          element: <BlogForm />,
        },
      ],
    },
    {
      path: "settings",
      children: [
        {
          path: "",
          element: <Settings />,
        },
      ],
    },
  ],
};

export default AdminRoutes;
