const events = {
  IS_USER: "IS_USER",
  NEW_USER: "NEW_USER",
  INIT_CHATS: "INIT_CHATS",
  LOGOUT: "LOGOUT",
  MESSAGE_SEND: "MESSAGE_SEND",
  TYPING: "TYPING",
  ROOM_ID: "ROOM_ID",
  P_MESSAGE_SEND: "P_MESSAGE_SEND",
  READ_MESSAGE: "READ_MESSAGE",
  P_TYPING: "P_TYPING",
  CHECK_CHANNEL: "CHECK_CHANNEL",
  CREATE_CHANNEL: "CREATE_CHANNEL",
  USER_IDEAL: "USER_IDEAL",
};

export default events;
