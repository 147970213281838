import { createSlice } from "@reduxjs/toolkit";

const initialState: { data: any; loader: boolean } = {
  data: {},
  loader: false,
};

const homepage = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    setHomepage(state, action) {
      state.data = action?.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
  },
});

export const { setHomepage, setLoader } = homepage.actions;
export default homepage.reducer;
