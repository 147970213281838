import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  notification: Array<any>;
  removeMessageId: string;
  count: number;
} = {
  notification: [],

  count: 0,
};

const notifications = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification(state, action) {
      state.notification = action.payload;
    },
    setRemoveMessageId(state, action) {
      // state.notification = state.notification.filter(
      //   (item) => item._id !== action.payload
      // );
    },
    setNotificationCount(state, action) {
      state.count = action.payload;
    },
  },
});

export const { setNotification, setRemoveMessageId, setNotificationCount } =
  notifications.actions;
export default notifications.reducer;
