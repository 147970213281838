import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
  ViewBlogs: [],
  search: "",
  pageIndex: 1,
  pageSize: 10,
  count: 0,
};

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    getBlogs(state, action) {
      return {
        ...state,
        blogs: action.payload,
      };
    },
    setBlogPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setBlogPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setBlogSearch(state, action) {
      state.search = action.payload;
    },
    setBlogStatus(state, action) {
      const blogData: any = state.blogs.map((blog: any) => {
        if (blog._id === action.payload)
          return { ...blog, isPublished: !blog.isPublished };
        return blog;
      });
      state.blogs = blogData;
    },
    delBlogs(state, action) {
      const updatedBlogs = state.blogs.filter(
        (item: any) => item._id !== action.payload
      );
      const updatedCount = state.count > 0 ? state.count - 1 : 0;

      return {
        ...state,
        blogs: updatedBlogs,
        count: updatedCount,
      };
    },
    setViewBlogs(state, action) {
      return {
        ...state,
        ViewBlogs: action.payload,
      };
    },
  },
});

export const {
  getBlogs,
  setBlogPageIndex,
  setBlogPageSize,
  setBlogSearch,
  setBlogStatus,
  setCount,
  delBlogs,
  setViewBlogs,
} = blogSlice.actions;

export default blogSlice.reducer;
