import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// project-imports
import useAuth from "hooks/useAuth";

// types
import { GuardProps } from "types/auth";
import axios from "axios";
import { ADMIN_DEFAULT_PATH, APP_DEFAULT_PATH } from "config";
// ==============================|| AUTH GUARD ||============================== //

const AdminGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.hostname?.split(".")[0] !== "admin" && isLoggedIn) {
      if (user?.type === "admin") navigate(ADMIN_DEFAULT_PATH);
      else navigate(APP_DEFAULT_PATH);
    } else if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default AdminGuard;
