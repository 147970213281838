import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL + "auth";

// call api's with /auth as base pathname 

export const loginService = (reqData: Object) => {
  return axios.post(`${API_URL}/login`, reqData);
};
export const adminLoginService = (reqData: Object) => {
  return axios.post(`${API_URL}/admin/login`, reqData);
};

export const logoutService = (reqData: Object) => {
  return axios.put(`${API_URL}/logout`, reqData, {
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};

export const checkEmail = (reqData: Object) => {
  return axios.get(`${API_URL}/checkEmail`, { params: reqData });
};

export const userSignup = (reqData: Object) => {
  return axios.post(`${API_URL}/signup`, reqData);
};

export const verifyPassCode = (reqData: Object) => {
  return axios.put(`${API_URL}/verifyPassCode`, reqData);
};
export const getUserById = (reqData?: Object) => {
  return axios.get(API_URL + "/token", {
    params: reqData,
    headers: { Authtoken: localStorage.getItem("serviceToken") },
  });
};
export const resendEmail = (reqData?: Object) => {
  return axios.put(API_URL + "/resend", reqData);
};
