// ===========================|| JWT - USERS ||=========================== //

const users = [
  {
    id: '5e86809283e28b96d2d38537',
    email: 'info@phoenixcoded.co',
    password: '123456',
    name: 'JWT User'
  }
];

export default users;
