import { Link } from "react-router-dom";
import { To } from "history";

// material-ui
import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/system";

// project-imports
import Logo from "./LogoMain";
import LogoIcon from "./LogoIcon";
import { ADMIN_DEFAULT_PATH, APP_DEFAULT_PATH } from "config";

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase
    disableRipple
    component={Link}
    to={
      !to
        ? location?.hostname?.split(".")[0] === "admin"
          ? location.pathname === "/login" ||
            location.pathname === "/forgot-password" ||
            location.pathname === "/reset-password" ||
            location.pathname === "/code-verification"
            ? "/login"
            : ADMIN_DEFAULT_PATH
          : APP_DEFAULT_PATH
        : to
    }
    sx={sx}
  >
    {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
  </ButtonBase>
);

export default LogoSection;
