// third-party
import { FormattedMessage } from "react-intl";

// assets
import { UserSquare } from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  icon1: UserSquare,
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const users: NavItemType = {
  id: "group-users",
  type: "group",
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: "users",
      title: <FormattedMessage id="Users" />,
      type: "item",
      url: "/users",
    },
  ],
};

export default users;
