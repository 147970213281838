import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  filter: any;
  // features: Array<string>;
  // trims: Array<string>;
} = {
  filter: {},
  // features: [],
  // trims: [],
};

const filter = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    setFilterData(state, action) {
      state.filter = action?.payload;
    },
    // setFeatures(state, action) {
    //   state.features = action?.payload;
    // },
    // setTrimResult(state, action) {
    //   state.trims = action?.payload;
    // },
  },
});

export const { setFilterData } = filter.actions;
export default filter.reducer;
