// material-ui
import { Box } from "@mui/material";

// project-imports
import Profile from "./Profile";
// import Notification from './Notification';
// import MobileSection from './MobileSection';

import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";

// type
import { LAYOUT_CONST } from "types/config";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  // const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && (
        <DrawerHeader open={true} />
      )}
      <Box sx={{ display: "flex", marginLeft: "auto" }}>
        {/* {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}

        {/* <Notification /> */}
        <Profile />
        {/* {downLG && <MobileSection />} */}
      </Box>
    </>
  );
};

export default HeaderContent;
