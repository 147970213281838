//these are main routes page where all routes are defined except admin routes
import { lazy } from "react";

// project-imports
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Navbar2 from "pages/Navbar2";
import PrivateRoute from "utils/route-guard/PrivateRoute";

const Homepage = Loadable(lazy(() => import("pages/landing/UserLanding")));
// const Consultant = Loadable(
//   lazy(() => import("pages/consultant/ConsultantLanding"))
// );
const Home = Loadable(lazy(() => import("pages/Home")));
const MyAccount = Loadable(lazy(() => import("pages/MyAccount/MyAccount")));
// const IntrestedBuyer = Loadable(
//   lazy(() => import("pages/landing/IntrestedBuyer"))
// );
const Product = Loadable(lazy(() => import("pages/product/Product")));

const Customer = Loadable(
  lazy(() => import("pages/consultant/ViewConsultant"))
);
const BlogList = Loadable(lazy(() => import("pages/Blogs/BlogList")));
const Blog = Loadable(lazy(() => import("pages/Blogs/BlogView")));
// const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
// const MaintenanceError500 = Loadable(
//   lazy(() => import("pages/maintenance/500"))
// );
// const MaintenanceUnderConstruction = Loadable(
//   lazy(() => import("pages/maintenance/under-construction"))
// );
// const MaintenanceComingSoon = Loadable(
//   lazy(() => import("pages/maintenance/coming-soon"))
// );

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));

const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon"))
);

// const CProfile = Loadable(lazy(() => import("pages/profile/ConsultanProfile")));

// render - sample page

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <>
            <Navbar2 />
            <CommonLayout />
          </>
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: <PrivateRoute />,
          children: [
            { path: "home", element: <Homepage /> },
            // { path: "intrestedBuyers", element: <IntrestedBuyer /> },
            { path: "view", element: <Product /> },
            { path: "customer", element: <Customer /> },
            {
              path: "myAccount",
              children: [
                { path: "", element: <MyAccount /> },
                {
                  path: "payment",
                  children: [
                    { path: "", element: <MyAccount /> },
                    { path: "New-Card", element: <MyAccount /> },
                  ],
                },
                { path: "Add-Card", element: <MyAccount /> },
              ],
            },
          ],
        },
        { path: "/", element: <Home /> },
        { path: "bloglist", element: <BlogList /> },
        // { path: "blog/", children: [{ path: "*", element: <Blog /> }] },
        { path: "blog/:slug", element: <Blog /> },
        // { path: "consultant", element: <Consultant /> },
        {
          path: "car/*",
          element: <MaintenanceComingSoon />,
        },
        {
          path: "cars/*",
          element: <MaintenanceComingSoon />,
        },
        {
          path: "error",
          element: <MaintenanceError />,
        },
        {
          path: "*",
          element: <MaintenanceError />,
        },
        // { path: "profile", element: <CProfile /> },
      ],
    },
  ],
};

export default MainRoutes;
