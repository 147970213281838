import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userlist: [],
  greyLoader: false,
  sortingData: [
    {
      id: "created_at",
      sort: true,
      desc: true,
    },
  ],
  type: "",
  startDate: "",
  endDate: "",
  pageIndex: 1,
  size: 10,
  count: 0,
  settingCount: "",
  search: undefined,
};

const userlist = createSlice({
  name: "userlist",
  initialState,
  reducers: {
    updateuserlist(state, action) {
      state.userlist = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setuserlistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload,
      };
    },
    setSettingCount(state, action) {
      state.settingCount = action.payload;
    },
    setGreyLoader(state, action) {
      state.greyLoader = action.payload;
    },
    setuserlistSearch(state, action) {
      state.search = action.payload;
    },
    deluserlist(state: any, action) {
      state.userlist = state.userlist.map(
        (item: { _id: any; deleted: boolean; isActive: boolean }) => {
          if (item._id === action.payload) {
            return {
              ...item,
              deleted: !item.deleted,
              isActive: !item.isActive,
            };
          }
          return item;
        }
      );
      if (state.count > 0) {
        state.count = state.count - 1;
      }
    },

    setuserlistTableSize(state, action) {
      return {
        ...state,
        size: action.payload,
      };
    },
    setSortingValue(state, action) {
      return {
        ...state,
        sortingData: action.payload,
      };
    },
    setTypeValue(state, action) {
      return {
        ...state,
        type: action.payload,
      };
    },
    setStartDate(state, action) {
      return {
        ...state,
        startDate: action.payload,
      };
    },
    setEndDate(state, action) {
      return {
        ...state,
        endDate: action.payload,
      };
    },
  },
});

export const {
  updateuserlist,
  setCount,
  setuserlistSearch,
  setSettingCount,
  setuserlistPageIndex,
  setuserlistTableSize,
  deluserlist,
  setSortingValue,
  setGreyLoader,
  setTypeValue,
  setEndDate,
  setStartDate,
} = userlist.actions;
export default userlist.reducer;
