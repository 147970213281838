// third-party
import { FormattedMessage } from "react-intl";

// assets
import { Paperclip } from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  icon1: Paperclip,
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const blogs: NavItemType = {
  id: "group-blogs",
  type: "group",
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: "blogs",
      title: <FormattedMessage id="Blogs" />,
      type: "item",
      url: "/blogs",
    },
  ],
};

export default blogs;
