import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.REACT_APP_TRACKING_ID) {
        // Google Analytics gtag.js code
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_TRACKING_ID}`;
        document.head.appendChild(script);

        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', process.env.REACT_APP_TRACKING_ID);
        };
    }
  }, []);

  return null; // or you can return a placeholder div if needed
};

export default GoogleAnalytics;